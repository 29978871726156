import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  orientation: "front",
  cubes: [
    {id: 1, title:'WRSH', faculty: 'Phil.-hum.', route:'frueherkennung-und-praevention',  orientation: 'front', frontFace: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/K01.png",colors:[{background: "#580236"}, {background: "#780249"}, {background: "#8C0355"}, {background: "#A00361"}, {background: "#B4046D"}, {background: "#000"}, ]}, // Amina Roci & Jeremy Küng
    {id: 2, title:'BMG', faculty: 'WISO', route:'einfuehrung-in-die-mathematik-fuer-wiso-I',  orientation: 'front', frontFace: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/K02.png",colors:[{background: "#5E0B08"}, {background: "#710D0A"}, {background: "#830F0B"}, {background: "#96120D"}, {background: "#A9140F"}, {background: "#899FBD"}, ]}, //Sascha Künzler
    {id: 3, title:'SBER', faculty: 'Vetsuisse', route:'morphologische-grundlagen-I',  orientation: 'front' , frontFace: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/K03.png",colors:[{background: "#7A6600"}, {background: "#8F7700"}, {background: "#A38800"}, {background: "#B89900"}, {background: "#CCAA00"}, {background: "#9FD1CE"}, ]}, //Livia Flückiger
    {id: 4, title:'SH', faculty: 'Phil.-hum.', route:'psychopathologie',  orientation: 'front', frontFace: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/K04.png",colors:[{background: "#2B0A3E"}, {background: "#3D0E58"}, {background: "#49116A"}, {background: "#55147B"}, {background: "#61178C"}, {background: "#E4C8CE"}, ]}, // Noe Bayard
    {id: 5, title:'LY', faculty: '', route:'Page-5',  orientation: 'front', frontFace: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/MicrosoftTeams-image%20(6).png",colors:[{background: "#000000"}, {background: "#B93435"}, {background: "#C5484A"}, {background: "#D25D5E"}, {background: "#DE7173"}, {background: "#EA8687"}, ]},//
    {id: 6, title:'SW', faculty: 'Phil.-hum.', route:'psychologische-diagnostik',  orientation: 'front', frontFace: " https://vipro2022.fra1.cdn.digitaloceanspaces.com/K05.png",colors:[{background: "#4F542D"}, {background: "#646A39"}, {background: "#717740"}, {background: "#7D8547"}, {background: "#89924F"}, {background: "#DA9875"}, ]}, //Victoria und Enea
    {id: 7, title:'IK', faculty: 'Phil.-hum.', route:'affektive-neurowissenschaften',  orientation: 'front', frontFace: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/K06.png",colors:[{background: "#100A54"}, {background: "#150D6E"}, {background: "#180F80"}, {background: "#1C1192"}, {background: "#1F13A4"}, {background: "#F2E9BA"}, ]}, //Ronja
    {id: 8, title:'DSGG', faculty: 'WISO', route:'einfuehrung-in-qualitative-forschungsmethoden',  orientation: 'front', frontFace: " https://vipro2022.fra1.cdn.digitaloceanspaces.com/K07.png" ,colors:[{background: "#22404D"}, {background: "#2C5363"}, {background: "#335F71"}, {background: "#396B7F"}, {background: "#3F778D"}, {background: "#D0D5AF"}, ]}, // Ronja
    {id: 9, title:'LC', faculty: 'Phil.-hum.', route:'datensatzaufbereitung-in-r',  orientation: 'front', frontFace: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/K08.png" ,colors:[{background: "#0B471D"}, {background: "#0E5824"}, {background: "#116A2B"}, {background: "#147B33"}, {background: "#178C3A"}, {background: "#9EC5A0"}, ]} //Florian
  ],
  cubesBefore:[], // nur für die Animation relevant
  cubesAfter:[],  // nur für die Animation relevant
  menuView: 'big',      //nur für die Animation relevant
  menuViewUntouched: true,    //bestimmt ob Video angezeigt wird
  muted: true,
  paused: false,

  
  projects: {                 //das ist eine Lise aller Projekte im JSON-Format
    
    //    +-------------------------+
    //    | Advanced Organization I |   
    //    +-------------------------+
    
    'frueherkennung-und-praevention':{         // hier beginnt das Projekt advanced organization I. "advanced-organization-I" ist ein slug und muss mit der route aus den würfeln übereinstimmen. Sonst kann das Projekt nicht gefunden werden.
      index: 1,                         //Jedes Projekt hat eine Nummer von 1-9, die Nummern sind identisch mit der id: eigenschaft auf den Würfeln
      quotecolor: '#580236',
      backgroundLight: '#E7DAE2',       // Jedes Projekt hat eine eigenes Farbkonzept. Die Projektseite selbst hat dann jeweils einen helleren und einen dunkleren Farbton
      backgroundDark: '#CBAEC0',
      title: 'Früh&shy;erkennung und Prävention',       // Der Titel wird so auf der Projektseite Angezeigt
      introductions:[
        'Schön, dass Sie Ihren Weg zu unserem Projekt gefunden haben! Während des HS22 durften wir Aileen Wosniak bei der Gestaltung ihres Masterseminars «Früherkennung und Prävention bei Jugendlichen und jungen Erwachsenen mit erhöhtem Psychoserisiko» begleiten. Im Fokus standen Interaktion und Einfachheit: Anhand verschiedener digitaler sowie analoger Tools – seien es ILIAS-Funktionen, Miroboards oder Karteikarten in Papierform –, konnten wir den Austausch zwischen Studierenden sowie zwischen den Studis, der Dozentin und den eCoaches möglichst einfach und doch abwechslungsreich gestalten.  '
      ],
      introductionimg: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/aminaSinnbild.jpg",      // die Quelle zu dem ersten Bild ganz rechts
      lecturers:[                                                                                                                                           // hier kann einer oder mehrere Dozenten aufgelistet werden. Die Eigenschaft orientation sagt aus, ob der Text rechts oder links des Bildes ist
        {name: 'M. Sc. Aileen Wosniak', role: '', imgsrc2:"", imgsrc:'https://vipro2022.fra1.cdn.digitaloceanspaces.com/Aileen_Wosniak%20(A%26J)quadratisch.jpg',
        quote: 'Ich hatte mich um eCoaches beworben, da ich mir dadurch interaktive Lehre mit verschiedenen medialen Tools erhofft hatte. Meine Erwartungen wurden mehr als übertroffen. Die Zusammenarbeit verlief reibungslos und ich konnte von der Kompetenz und der Leidenschaft der eCoaches profitieren. Wir hatten spassige, ereignisreiche und schöne Momente. Das Seminar wurde ein Mix aus interaktiven Tools, ILIAS wurde zur «Augenweide» durch den beherzten, engagierten Einsatz der eCoaches. Sie sind eine Bereicherung und «State of the Art», was die wissenschaftlich fundierte universitäre Lehre angeht.',
        orientation: 'right'},
        {name: 'Amina Roçi', role: 'eCoach', imgsrc2:"", imgsrc:'https://vipro2022.fra1.cdn.digitaloceanspaces.com/AminaRoci%20quadratisch.jpg',
        quote: 'Durch die Arbeit als eCoach habe ich verschiedene spannende Persönlichkeiten sowie unterschiedliche Arbeitsweisen kennenlernen dürfen. Die konsistente und kontinuierliche Arbeit an diesem Projekt hat mich aber nicht nur viele Dinge gelehrt, sondern mir auch unglaubliche Freude bereitet. Gemeinsam konnten wir eine Lehrveranstaltung attraktiv gestalten, deren Inhalt nachhaltig weiterverwendet werden kann. Insgesamt ein vollkommen gelungenes Projekt!',
        orientation: 'left'},
        {name: 'Jeremy Küng', role: 'eCoach', imgsrc2:"", imgsrc:'https://vipro2022.fra1.cdn.digitaloceanspaces.com/JeremyKu%CC%88ng%20quadratisch.jpg',
        quote: 'Die Arbeit als eCoach im Einsatz war sehr spannend und lehrreich. Bereits wir beiden eCoaches kommen aus jeweils verschiedenen Fachrichtungen, diejenige unserer Dozentin ist nochmals eine andere. Trotzdem konnten wir unsere Kenntnisse auch einbringen und viel Neues lernen. Die Zusammenarbeit mit der Dozentin lief reibungslos und enthielt allezeit eine Spur Humor und Spass.',
        orientation: 'right'}
      ],
      timelineItems:[                                                                                                                                      // Eine liste mit allen Einträgen der Timeline. Es kann eine beliebige anzahl an Einträgen gemacht werden.
        {                        
          index: 0,             // der Index sollte bei 0 starten und die weiteren einträge müssen durchgehend nummeriert sein
          title: "Kennenlernphase",
          content:
            "Ende Mai fand das erste Kennenlernen über Zoom statt. Dieses erste Beschnuppern diente lediglich zum Austausch von Informationen ohne konkretes Planen. Persönlich haben wir uns Ende Juni erstmals getroffen. Es herrschte eine angenehme Stimmung und wir verstanden uns sehr schnell.\n ",
          src: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/SB_Kennenlernphase%20Quadratisch.png"
        },
        {
          index:1,
          title: "Planungsphase",
          content:
            "Ab Juli haben wir die verschiedenen Sitzungen zu planen begonnen. Für die ersten vier Termine haben wir den Sommer über alles vorbereitet, die restlichen Sitzungen haben wir laufend während des Semesters gestaltet.",
          src: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/SB_Planungsphase%20Quadratisch.png"
        },
        {
          index: 2,
          title: "Ausführphase",
          content:
            "Im Laufe des Semesters haben wir die weiteren Lehrveranstaltungstermine geplant. Dies lag unter anderem daran, dass die Studierenden simultan ihre interaktiven Präsentationen durchführten. Der Unterricht musste auf die jeweiligen Präsentationen abgestimmt sein, damit die Studierenden auch einen theoretischen Input von der Dozentin erhielten, passend zum von ihren Kommiliton/innen präsentierten Thema.",
          src: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/SB_Ausfu%CC%88hrungsphase%20Quadratisch.png"
        },
        {
          index: 3,
          title: "Abschlussphase",
          content:
              "Gegen Ende Semester haben wir ein zweites Selbststudium eingeplant sowie die Studierenden auf die Erstellung ihrer schriftlichen Arbeit vorbereitet. Die Unterlagen zum Seminar stehen ihnen auf ILIAS zur Verfügung und könnten von der Dozentin für ein weiteres Seminar verwendet werden.",
          src: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/test.png"
        }
      ],
      //TODO: Insert correct images
      contents:[ // hier ist eine Liste von beliebiger Länge, in der die weiteren Impressionen des Projektes wiedergegeben werden können. Jeder Eintrag räpresentiert ein Bild mit dem seitlich angebrafchten Text. orientation 
        { imgsrc:'https://vipro2022.fra1.cdn.digitaloceanspaces.com/Ilias-Special%2FAllgemein_Jeremi.png', orientation: 'left', title:'ILIAS Kursraum', // orientation left bedeutet, dass das Bild auf der linken seite ist. Bei Background muss abwechslungsweise der hellere oder der dunklere farbton angegeben werden.
          background:'#CBAEC0', text:'Den ILIAS-Kursraum für das Seminar haben wir in vier Bereiche gegliedert. Im ersten werden allgemeine Informationen zur Veranstaltung und zur Leistungskontrolle aufgeführt. Wir haben zudem zwei Foren eingerichtet, in welchen die Studierenden ihre Fragen und Anliegen posten können. Im zweiten Bereich befinden sich die Theorieblöcke der Dozentin, im dritten die Gruppenräume der Studierenden, welche sie für ihre interaktiven Präsentationen verwenden durften. Der vierte und letzte Bereich bietet Tipps und Tricks für die Studierenden, die wir in Absprache mit der Dozentin gestaltet haben. Darin finden die Studierenden Hinweise zur interaktiven und spannenden Gestaltung einer Präsentation sowie Ansätze und Vorschläge zur schriftlichen Arbeit.' },
          { imgsrc:'https://vipro2022.fra1.cdn.digitaloceanspaces.com/Ilias-Special%2FSpecial_Jeremi.png', orientation: 'right', title:'Spezials',
          background:'#E7DAE2', text:'Unser Wunsch war es, einen innovativen Touch in unseren ILIAS-Kursraum einzubringen, welcher den Studierenden möglichst lange im Gedächtnis bleibt. Dies haben wir vor allem anhand des dritten Bereichs umzusetzen versucht. Wir haben jeder präsentierenden Gruppe eine eigene ILIAS-Gruppe angelegt, die eine Vorlage enthält, welche bei der Gestaltung der Gruppe helfen sollte. Mithilfe letzterer haben sie ihren Raum selbständig als ILIAS-Administrierende bearbeiten und gestalten können. Die Gestaltung des Raumes war freiwillig und hatte keinen direkten Einfluss auf die Benotung. Trotzdem hat jede Gruppe ihren Raum individuell und passend zu ihrem Thema gestaltet.' },
      ],
      videosrc: 'https://vipro2022.fra1.cdn.digitaloceanspaces.com/Videos%2FAmina%20%26%20Jeremy%20Video.mp4',
      videoBG: '#CBAEC0'
    }, // Ende

    'einfuehrung-in-die-mathematik-fuer-wiso-I':{
      index: 2,
      quotecolor: '#5E0B08',                        //Jedes Projekt hat eine Nummer von 1-9, die Nummern sind identisch mit der id: eigenschaft auf den Würfeln
      backgroundLight: '#EED3D3',       // Jedes Projekt hat eine eigenes Farbkonzept. Die Projektseite selbst hat dann jeweils einen helleren und einen dunkleren Farbton
      backgroundDark: '#DDA5A5',
      title: 'Ein&shy;führung in die Mathe&shy;matik für WISO I',       // Der Titel wird so auf der Projektseite Angezeigt
      introductions:[
        'Herzlich Willkommen zur «Einführung in die Mathematik für WISO I». Es handelt sich hierbei um eine Einfürungsvorlesung für Studierende der Wirtschafts- und Sozialwissenschaften, welche im HS22 stattgefunden hat.',
        'Sie werden sehen, dass man auf einfache Art und Weise ein ILIAS-Kursraum übersichtlich gestalten und die etlichen Tools sinnvoll einsetzen kann. Den Studierenden wurden eine Vielzahl an Möglichkeiten zur Verfügung gestellt, um sich interaktiv mit den Lehrinhalten auseinander zu setzen.'
      ],
      introductionimg: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/Finale-Sinnbilder%2FRaw%20Sascha.jpg",      // die Quelle zu dem ersten Bild ganz rechts
      lecturers:[                                                                                                                                           // hier kann einer oder mehrere Dozenten aufgelistet werden. Die Eigenschaft orientation sagt aus, ob der Text rechts oder links des Bildes ist
        {name: 'Dr. Kinga Sipos', role: '', imgsrc2:"", imgsrc:'https://vipro2022.fra1.cdn.digitaloceanspaces.com/Sipos_Kinga%20(Sascha)%20quadratisch.jpg',
          quote: 'Ich habe ein digitales Bonusprogramm für eine erstsemestrige Grossveranstaltung geplant, welche viele neue Komponenten hat. Der eCoach erstellte eine übersichtliche ILIAS-Seite für den Kurs und beriet mich bei der geschickten Nutzung des Tools. Damit konnte ich auf die Entwicklung neuer digitalen Inhalte fokussieren und alles wie geplant einsetzen. Dank Saschas grosser Freude an den Aufgaben, seines Engagements und seinen Programmierkenntnisse konnten wir sogar das Unvorhergesehene lösen!',
          orientation: 'right'},                                                                                                                                          // auch hier kann einer oder mehrere eCoaches aufgelistet werden. Die Eigenschaften sind identisch zu denen der Dozierenden
        {
          name: 'Sascha Künzler', role: 'eCoach', imgsrc2:"", imgsrc: 'https://vipro2022.fra1.cdn.digitaloceanspaces.com/SaschaKu%CC%88nzler%20quadratisch.jpg',
          quote: 'Die Zusammenarbeit mit Kinga hat Spass gemacht, da man in den Meetings immer auf gute Lösungsansätze für Probleme gekommen ist. Es war auch interessant, dass ich mein Wissen aus dem Studium in das Projekt integrieren konnte und anhand von Programmierung eine Vielzahl an Tests auswerten konnte, was man manuell nicht hätte machen können.',
          orientation: 'left'}
      ],
      timelineItems:[                                                                                                                                      // Eine liste mit allen Einträgen der Timeline. Es kann eine beliebige anzahl an Einträgen gemacht werden.
        {
          index: 0,             // der Index sollte bei 0 starten und die weiteren einträge müssen durchgehend nummeriert sein
          title: "Kennenlernphase",
          content:
              "Am Anfang haben wir uns physisch getroffen, damit man sich etwas kennenlernen kann. Hierbei wurde natürlich nicht nur über das Projekt gesprochen, sondern auch über Allgemeines aus dem Leben, wie es sich beim Kennenlernen eben gehört.",
          src: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/SB_Kennenlernphase%20Quadratisch.png" //TODO: Picture is not square
        },
        {
          index:1,
          title: "Planungsphase",
          content:
              "Vor Semesterbeginn haben wir uns über den Aufbau der Vorlesung ausgetauscht. Kinga hatte bereits eine Vorstellung davon, was sie alles machen könnte und wie sie das Ganze durchführen möchte. Während einigen Wochen haben wir dies zusammen ausgearbeitet und einen Semesterplan erstellt.",
          src: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/SB_Planungsphase%20Quadratisch.png"
        },
        {
          index: 2,
          title: "Ausführungsphase",
          content: //TODO: holprig?
              "Unser Plan wurde fast wie geplant ausgeführt. Einige Sachen wurden on-the-fly umgeplant, da es das erste Mal war, dass die Vorlesung in dieser Form durchgeführt wurde. Entsprechend kann so auch Unvorhergesehenes auftreten. Während der Ausführungsphase wurde weiterhin geplant, was dann wieder umgesetzt wurde.",
          src: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/SB_Ausfu%CC%88hrungsphase%20Quadratisch.png"
        },
        {
          index: 3,
          title: "Abschlussphase",
          content:
              "Damit die ganzen Scripts auch in künftigen Vorlesungen genutzt werden können, wurde das Ganze einerseits innerhalb des Codes, wie auch als PDF-Anleitung dokumentiert. Die Skripts sind so konzipiert, dass man sie auch für andere Vorlesungen verwenden kann, welche eine grosse Teilnehmendenzahl aufweisen.",
          src: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/test.png"
        }
      ],
      contents:[ // hier ist eine Liste von beliebiger Länge, in der die weiteren Impressionen des Projektes wiedergegeben werden können. Jeder Eintrag räpresentiert ein Bild mit dem seitlich angebrafchten Text. orientation
        { imgsrc:'https://vipro2022.fra1.cdn.digitaloceanspaces.com/Ilias-Special%2FAllgemein_Sascha.jpg', orientation: 'left', title:'Ilias Kursraum', // TODO: change picture
          background:'#EED3D3', text:'Am Anfang unseres ILIAS-Kurses haben wir eine Übersicht über alles Administrative erstellt. Dadurch gab es merklich weniger E-Mails, da wir offene Fragen vorgängig klären konnten. Danach haben wir alles Material der Vorlesung (Notizen, Podcasts etc.) in einem weiteren Block bereitgestellt.' },
        { imgsrc:'https://vipro2022.fra1.cdn.digitaloceanspaces.com/Ilias-Special%2FSpezial_Sascha.jpg', orientation: 'right', title:'Bonusprogramm',
          background:'#DDA5A5', text:'Das dritte Hauptelement waren dann die digitalen Übungen des Bonusprogramms, welche man durchführen konnte oder die Abgaben, welche die Studierenden regelmässig einreichen sollten. Das Ziel war es, eine möglichst übersichtliche ILIAS-Seite zu gestalten, welche intuitiv nutzbar ist und wo die Studierenden alles Relevante schnell finden.' }
      ],
      videosrc: 'https://vipro2022.fra1.cdn.digitaloceanspaces.com/Videos%2FSascha%20Video.mp4',
      videoBG: '#EED3D3'
    }, //Ende

    'morphologische-grundlagen-I':{
      index: 3,
      quotecolor: '#7A6600',                       //Jedes Projekt hat eine Nummer von 1-9, die Nummern sind identisch mit der id: eigenschaft auf den Würfeln
      backgroundLight: '#E7E1C5',       // Jedes Projekt hat eine eigenes Farbkonzept. Die Projektseite selbst hat dann jeweils einen helleren und einen dunkleren Farbton
      backgroundDark: '#D8CFA2',
      title: 'Morpho&shy;logische Grund&shy;lagen I',       // Der Titel wird so auf der Projektseite Angezeigt
      introductions:[
        'Für die Erstjahresveranstaltung für «Morphologische Grundlagen 1» vom HS22 haben wir den Teil über die Anatomie der Schultergliedmassen gestaltet. Wir versuchten, das Selbststudium für die Studierenden zu vereinfachen, indem wir Selbsttests und Lernmodule zur Vor- und Nachbearbeitung der Vorlesung bereitstellten. Die darin enthaltenen Fragen sind vom Schwierigkeitsgrad ähnlich wie die Prüfungsfragen, was die Studierenden optimal auf das Schlussexamen vorbereitet. Zudem versuchten wir, die Vorlesung mit Fragen an die Studierenden aufzulockern und interaktiver zu gestalten.',
        'Auch für den Präparierkurs hatten wir etwas Spezielles geplant: Wir haben eine Bewilligung für die Anschaffung von zwei TableVet-Stationen der Firma Anatomage erhalten, für welche wir zusammen ein Lehrkonzept erstellt und eingereicht haben.'
      ],
      introductionimg: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/Finale-Sinnbilder%2FRaw%20Livia.jpg",      // die Quelle zu dem ersten Bild ganz rechts
      lecturers:[                                                                                                                                           // hier kann einer oder mehrere Dozenten aufgelistet werden. Die Eigenschaft orientation sagt aus, ob der Text rechts oder links des Bildes ist
        {name: 'Dr. med. vet. Barbara Drews', role: '', imgsrc:'https://vipro2022.fra1.cdn.digitaloceanspaces.com/Barbara_Drews%20(Livia)%20quadratisch.png',  //TODO: von wem genau?
          quote: 'Mein eCoach Livia hat sich gleich zu Anfang als sehr kompetente, liebenswürdige Person herausgestellt. Die Einrichtung eines ILIAS-Kursraumes für die Vorlesungsreihe inklusive praktischer Kurse war ein voller Erfolg. Allerdings weiss ich nicht, inwieweit ich dies alleine fortführen kann, da ILIAS ein recht sperriges, zeitintensives Programm ist. Ich habe jedoch viele Inputs bekommen, wie z. B. den Einsatz digitaler Quiz, um die Vorlesung aufzulockern und die aktive Mitarbeit der Studierenden zu fördern.',
          orientation: 'right'},                                                                                                                                         // auch hier kann einer oder mehrere eCoaches aufgelistet werden. Die Eigenschaften sind identisch zu denen der Dozierenden
        {
          name: 'Livia Flückiger', role: 'eCoach', imgsrc2:"", imgsrc:'https://vipro2022.fra1.cdn.digitaloceanspaces.com/LiviaFlu%CC%88ckiger%20quadratisch.jpg',
          quote: 'Organisation ist mir sehr wichtig, weshalb ich viel Wert auf eine gepflegte und strukturierte ILIAS-Gestaltung lege. Trotz der vorgegebenen ILIAS-Struktur der Vetsuisse haben wir eine bunte und interaktive Lernumgebung für die Studierenden erstellt. Die Zusammenarbeit war insgesamt sehr erfolgreich, da wir zwei TableVet-Stationen für unsere Universität erhalten haben. Wir haben uns sehr gut ergänzt, da Barbara alle medizinischen Informationen bereitstellte und ich die technischen sowie didaktischen Inputs lieferte.',
          orientation: 'left'}
      ],
      timelineItems:[                                                                                                                                      // Eine liste mit allen Einträgen der Timeline. Es kann eine beliebige anzahl an Einträgen gemacht werden.
        {
          index: 0,             // der Index sollte bei 0 starten und die weiteren einträge müssen durchgehend nummeriert sein
          title: "Kennenlernphase",
          content:
              "Als unser Tandem im Juni gestartet ist, haben wir viele Ideen gesammelt, zusammengetragen und uns auf zwei bestimmte Projekte geeinigt, da die Zeit und das Pensum einschränkend waren.",
          src: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/SB_Kennenlernphase%20Quadratisch.png"
        },
        {
          index:1,
          title: "Planungsphase",
          content:
              "Wir versuchten, unser Vorhaben grob einzuteilen. Zum einen wollten wir ein Lehrkonzept mit der Integration einer TableVet-Station erstellen, damit wir die Gelder beantragen konnten und zum anderen wollten wir einen ILIAS-Kursraum mit einer organisierten Struktur erstellen.",
          src: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/SB_Planungsphase%20Quadratisch.png"
        },
        {
          index: 2,
          title: "Ausführphase",
          content:
              "Da der Semesterbeginn nicht mehr weit entfernt war, haben wir gleich mit dem Lehrkonzept für die TableVet-Integration begonnen. Als dies erledigt war, haben wir mit der ILIAS-Kursraumplanung begonnen, wo wir ein grobes Konzept mit den jeweiligen Farben erstellt haben. Zusätzlich haben wir eine Umfrage erstellt, um von den Studierenden ein Zwischenfeedback zu erhalten. So erfuhren wir, dass die Studierenden insbesondere die übersichtliche Struktur des Kursraumes schätzten.",
          src: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/SB_Ausfu%CC%88hrungsphase%20Quadratisch.png"
        },
        {
          index: 3,
          title: "Abschlussphase",
          content:
              "Da es keine durchgehende Veranstaltung war, konnten wir von Termin zu Termin die einzelnen Kachelinhalte erstellen. In der Abschlussphase haben wir die letzten organisatorischen Fragen geklärt und unsere Zusammenarbeit reflektiert.",
          src: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/test.png"
        }
      ],
      contents:[ // hier ist eine Liste von beliebiger Länge, in der die weiteren Impressionen des Projektes wiedergegeben werden können. Jeder Eintrag räpresentiert ein Bild mit dem seitlich angebrafchten Text. orientation
        { imgsrc:'https://vipro2022.fra1.cdn.digitaloceanspaces.com/Ilias-Special%2FAllgemein_Livia.png', orientation: 'left', title:'Ilias Kursraum',// orientation left bedeutet, dass das Bild auf der linken seite ist. Bei Background muss abwechslungsweise der hellere oder der dunklere farbton angegeben werden.
          background:'#E7E1C5', text:'Unser ILIAS-Kursraum ist mit drei Hauptfarben gekennzeichnet. lila: Selbststudium, blau: Vorlesung, braun: Präparierkurs. Mit diesen drei Farben haben wir Blöcke erstellt, in welche die jeweiligen Inhalte hochgeladen wurden, wie z.B. die Vorlesungsfolien oder die Anleitungen zur Präparation. Als Lernhilfe für das Selbststudium haben wir die ILIAS-Tools «Test» und «Lernmodul» verwendet. Die Veranstaltungsdaten, welche viel Platz brauchen, haben wir in einem Akkordeon versteckt. Derart haben wir die Lernmodule und Selbsttests auf der Hauptseite ebenfalls verlinkt und versteckt. Dadurch sind alle Inhalte beieinander und für die Studierenden einfach zugänglich.' },
        { imgsrc:'https://vipro2022.fra1.cdn.digitaloceanspaces.com/Ilias-Special%2FSpecial_Livia.png', orientation: 'right', title:'Special',
          background:'#D8CFA2', text:'Uns war es wichtig, ein Zwischenfeedback zu erhalten, weshalb wir als Special eine Umfrage erstellt haben, in welcher die Studierenden die Vorlesung und den ILIAS-Kursraum evaluieren konnten. Wir wollten herausfinden, ob die Studierenden die Selbsttests und Lernmodule zur Vor- bzw. Nachbearbeitung nutzten und ob sie sich im Kursraum zurechtfinden.\n' +
              'Als weiteres Special haben wir interaktive Bilder im Lernmodul erstellt, sogenannte «Imagemaps». In unserem Fall waren die Bilder Knochen, welche mit Nummern beschriftet waren. Sobald die Studierenden auf eine Nummer klickten, erschien der anatomische Name der jeweiligen Struktur.' }
      ],
      videosrc: 'https://vipro2022.fra1.cdn.digitaloceanspaces.com/Videos%2FLivia%20Video.mp4',
      videoBG: '#E7E1C5'
    }, //ende

    'psychopathologie':{
      index: 4,
      quotecolor: '#2B0A3E',                          //Jedes Projekt hat eine Nummer von 1-9, die Nummern sind identisch mit der id: eigenschaft auf den Würfeln
      backgroundLight: '#E2D9E8',       // Jedes Projekt hat eine eigenes Farbkonzept. Die Projektseite selbst hat dann jeweils einen helleren und einen dunkleren Farbton
      backgroundDark: '#C5B3D1',
      title: 'Psycho&shy;pathologie',       // Der Titel wird so auf der Projektseite Angezeigt
      introductions:[
        'Die Vorlesung «Psychopathologie» ist Teil des Bachelor-Studienprogramms in Psychologie und behandelt die wichtigsten Störungsbilder in der klinischen Psychologie.',
        'Mit einer effizienten Gestaltung des ILIAS-Kursraumes wurden die Lerninhalte übersichtlich und mit einfachem Zugang für die Studierenden dargestellt.',
        'Durch das Implementieren von Veranstaltungen im Format «Inverted Classroom» konnten die Studierenden aktiviert werden. Die dazu eingesetzten interaktiven Videos auf ILIAS dienten als Vorbereitung für die Veranstaltungen und stellten eine Möglichkeit dar, den gelernten Stoff selbstständig zu vertiefen.'
      ],
      introductionimg: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/Finale-Sinnbilder%2FRaw%20Noe%CC%81%20Sinnbild.JPG",      // die Quelle zu dem ersten Bild ganz rechts
      lecturers:[                                                                                                                                           // hier kann einer oder mehrere Dozenten aufgelistet werden. Die Eigenschaft orientation sagt aus, ob der Text rechts oder links des Bildes ist
        {name: 'Dr. Maria Stein', role: '', imgsrc2:"", imgsrc:'https://vipro2022.fra1.cdn.digitaloceanspaces.com/Maria_Stein%20(Noe%CC%81)quadratisch.jpg',
          quote: 'Das Ziel, die Vorlesung «Psychopathologie» durch das eCoaching zu verbessern und durch interaktive Inhalte anzureichern, wurde voll erfüllt. Der Kursraum in ILIAS sieht dank Noés Einsatz toll aus und die interaktiven Inhalte zur Vertiefung und Anwendung des erarbeiteten Wissens wurden von den Studierenden geschätzt.  Für mich war es toll, mich in dem Projekt auf die inhaltliche Entwicklung konzentrieren zu können und bei der technischen Umsetzung ganz auf Noés Fähigkeiten zu vertrauen. Aus meiner Sicht also eine gelungene, tolle und oft auch spassige Zusammenarbeit!',
          orientation: 'right'},                                                                                                                                          // auch hier kann einer oder mehrere eCoaches aufgelistet werden. Die Eigenschaften sind identisch zu denen der Dozierenden
        {
          name: 'Noé Bayard', role: 'eCoach', imgsrc2:"", imgsrc:'https://vipro2022.fra1.cdn.digitaloceanspaces.com/NoeBayard%20quadratisch.jpg',
          quote: 'Das Ausarbeiten des Grundkonzeptes einer Vorlesung und die damit verbundenen Probleme, die bewältigt werden mussten, waren für mich sehr interessant. Die Zusammenarbeit mit Maria und Tim war sehr angenehm und hat mir Freude bereitet.',
          orientation: 'left'}
      ],
      timelineItems:[                                                                                                                                      // Eine liste mit allen Einträgen der Timeline. Es kann eine beliebige anzahl an Einträgen gemacht werden.
        {
          index: 0,             // der Index sollte bei 0 starten und die weiteren einträge müssen durchgehend nummeriert sein
          title: "Kennenlernphase",
          content:
              "Das erste Treffen fand per Zoom statt. Dies diente dazu, sich ein Bild der entsprechenden Personen zu machen und einander kennenzulernen. Anfang Juli haben wir uns dann das erste Mal offline getroffen, was der Startschuss für die Planungsphase war.",
          src: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/SB_Kennenlernphase%20Quadratisch.png"
        },
        {
          index:1,
          title: "Plaungsphase",
          content:
              "Unser Ziel hierbei war es, ein genaues Konzept auszuarbeiten, welchem wir später folgen konnten. Schlussendlich hatten wir einen Plan für das ganze Semester und auch eine Vorstellung darüber, wie der ILIAS-Kursraum aussehen soll.",
          src: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/SB_Planungsphase%20Quadratisch.png"
        },
        {
          index: 2,
          title: "Ausführphase",
          content:
              "Nun war es an der Zeit, unser Konzept umzusetzen. Angefangen haben wir mit dem Design des ILIAS-Kursraumes, da dieses vor Semesterbeginn fertig werden musste. Danach haben wir angefangen, die Inverted-Classroom-Veranstaltungen vorzubereiten, das heisst insbesondere das Erstellen der interaktiven Videos.",
          src: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/SB_Ausfu%CC%88hrungsphase%20Quadratisch.png"
        },
        {
          index: 3,
          title: "Abschlussphase",
          content:
              "Abgeschlossen haben wir die Vorlesung mit einem letzten interaktiven Video, gefolgt von einer letzten Inverted-Classroom-Veranstaltung, sowie einer Evaluation der Vorlesung. Grundsätzlich haben wir die Vorlesung derart gestaltet, dass sie ohne grossen Aufwand auch in nachfolgenden Semestern eingesetzt werden kann.",
          src: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/test.png"
        }
      ],
      contents:[ // hier ist eine Liste von beliebiger Länge, in der die weiteren Impressionen des Projektes wiedergegeben werden können. Jeder Eintrag räpresentiert ein Bild mit dem seitlich angebrafchten Text. orientation
        { imgsrc:'https://vipro2022.fra1.cdn.digitaloceanspaces.com/Ilias-Special%2FAllgemein_Noe.png', orientation: 'left', title:'ILIAS-Kursraum', // orientation left bedeutet, dass das Bild auf der linken seite ist. Bei Background muss abwechslungsweise der hellere oder der dunklere farbton angegeben werden.
          background:'#E2D9E8', text:'Der ILIAS-Kursraum besteht aus einer Kachel pro Vorlesung, also aus insgesamt 14 Stück. In den Kacheln befinden sich jeweils Folien wie auch Podcasts der entsprechenden Vorlesung. Unterhalb dieser Kacheln findet man einen Ordner mit Zusatzmaterialien, sowie Forum und alle Podcast-Aufzeichnungen.' },
        { imgsrc:'https://vipro2022.fra1.cdn.digitaloceanspaces.com/Ilias-Special%2FSpezial_Noe.png', orientation: 'right', title:'Specials',
          background:'#C5B3D1', text:'Damit der ILIAS-Kursraum für die Studierenden übersichtlich bleibt, haben wir ein Farbschema für die 14 Kacheln erstellt, welches ganz oben im Kursraum erklärt wird und wie folgt lautet: Grün: Normale Präsenzveranstaltungen Rot: Veranstaltungen mit einer Inverted-Classroom-Komponente. In dieser Kachel befindet sich ein interaktives Video, welches vor der Vorlesung bearbeitet werden muss. Grau: Keine Präsenzveranstaltung, die Inhalte werden durch Selbststudium erarbeitet. Zuletzt bestand der ILIAS-Kursraum aus zehn grünen, drei roten und einer grauen Kachel.' }
      ],
      videosrc: 'https://vipro2022.fra1.cdn.digitaloceanspaces.com/Videos%2FNoe%CC%81%20Video.mp4',
      videoBG: '#E2D9E8'
    }, //ende

    'Page-5':{          // NOT SHOWN !!!!!!!!
      index: 5,
      quotecolor:'#AD1F21',                         //Jedes Projekt hat eine Nummer von 1-9, die Nummern sind identisch mit der id: eigenschaft auf den Würfeln
      backgroundLight: '#ED9798',       // Jedes Projekt hat eine eigenes Farbkonzept. Die Projektseite selbst hat dann jeweils einen helleren und einen dunkleren Farbton
      backgroundDark: '#E56B6F',
      title: 'Public sector digitalization',       // Der Titel wird so auf der Projektseite Angezeigt
      introductions:[
        'Herzlich willkommen unserem Projekt für das Seminar "Public Sector Digitalization',
        'Da dieses Seminar zum ersten Mal an der Universität Bern gelehrt wird, war die Konzipierung des ILIAS-Kursraumes sowie auch die englische Sprache eine Herausforderung. Der Dozent Srinivas Yeramsetti, und ich, Quentin gaben jeweils unseres Bestes, um einen intuitiven, zulänglichen, aber zugleich auch einen korrekten und geordneten ILIAS-Kursraum entstehen zu lassen. Es freut uns sehr, Euch diesen als fertiges Produkt vorstellen zu dürfen.'
      ],
      introductionimg: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/test.png",      // die Quelle zu dem ersten Bild ganz rechts
      lecturers:[                                                                                                                                           // hier kann einer oder mehrere Dozenten aufgelistet werden. Die Eigenschaft orientation sagt aus, ob der Text rechts oder links des Bildes ist
        {name: 'Dr. Srinivas Yerramsetti', role: '', imgsrc2:"", imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Portraits/Quentin/SrinivasYerramsettiSW.jpeg',
          quote: 'It\'s hardly possible to overstate the value, in the present state of human improvement, of placing human beings in contact with other persons dissimilar to themselves, and with modes of thought and action unlike those with which they are familiar. Such communication has always been... one of the primary sources of progress. – John S. Mill',
          orientation: 'right'},                                                                                                                                         // auch hier kann einer oder mehrere eCoaches aufgelistet werden. Die Eigenschaften sind identisch zu denen der Dozierenden
        {name: 'Quentin Lauer', role: 'eCoach', imgsrc2:"", imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Portraits/Quentin/QuentinLauerSW.png',
          quote: 'Der Austausch zwischen Dozenten und Studierenden ist meiner Meinung nach für eine gute Lehre entscheidend. Vor,während und nach der Vorlesung. Ich wünsche mir, dass diese Form der Interaktion stets vom Dozenten und von der Dozentin in Richtung Studierenden beachtet wird.',
          orientation: 'left'}
      ],
      timelineItems:[                                                                                                                                      // Eine liste mit allen Einträgen der Timeline. Es kann eine beliebige anzahl an Einträgen gemacht werden.
        {
          index: 0,             // der Index sollte bei 0 starten und die weiteren einträge müssen durchgehend nummeriert sein
          title: "Juli – August 2021",
          content:
              "Srinivas und ich kommen als Tandem zusammen. Srinivas und ich spielten von Anfang an mit der Idee, den Kursraum einfach, aber intuitiv zu halten. Dabei lehnen wir uns an bereits vorhandenen, häufig gebrauchten Mustern der Lehre an. Die Grundidee ist es, Herkömmliches mit Neuem zu kombinieren - sodass einerseits die Studierenden neue Perspektiven erleben können, jedoch sich jederzeit auf dem Kursraum zurechtfinden.",
          src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/5/%282%29_Zusammenkommen%20und%20Planung_cytonn-photography-n95VMLxqM2I-unsplash_ccexpress.jpeg"
        },
        {
          index:1,
          title: "August – September 2021",
          content:
              "Ich erstelle die ersten Konzepte und gestalte die ersten ILIAS-Seiten danach. Nachdem wir uns auf ein Modell geeinigt haben, führe ich dieses durch.",
          src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/5/%283%29_Konzipieren%20und%20Umsetzung_brands-people-Ax8IA8GAjVg-unsplash_ccexpress.jpeg"
        },
        {
          index: 2,
          title: "September 2021",
          content:
              "Nach einigen Tests und letztem Feinschliff schalten wir den Kursraum gemeinsam pünktlich zum Semesterbeginn für die Studierenden auf. ",
          src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/5/%284%29_Aufschalten_drew-patrick-miller-_o6AAx9dl_Y-unsplash_ccexpress.jpeg"
        },
        {
          index: 3,
          title: "September-Dezember 2021",
          content:
              "Seit Semesterbeginn werden regelmässig die betreffenden Präsentationen, Arbeitsblätter und Dokumente hochgeladen. Ich schaue dazu, dass die ILIAS-Seite stets aktuell gehalten wird und verbessere hier und da. ",
          src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/5/%285%29_Uploads,%20Aktualisierungen%20und%20Optimierungen_edge2edge-media-x21KgBfOd_4-unsplash_ccexpress.jpeg"
        }
      ],
      contents:[ // hier ist eine Liste von beliebiger Länge, in der die weiteren Impressionen des Projektes wiedergegeben werden können. Jeder Eintrag räpresentiert ein Bild mit dem seitlich angebrafchten Text. orientation
        { imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Content/5/01.png', orientation: 'left', title:'Kursraum',// orientation left bedeutet, dass das Bild auf der linken seite ist. Bei Background muss abwechslungsweise der hellere oder der dunklere farbton angegeben werden.
          background:'#ED9798', text:'Der Kursraum hat eine einfache Struktur - er ist in seinem Kernstück eine simple Website mit allen nötigen Infos. ' },
        { imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Content/5/02.png', orientation: 'right',title:'Seminar Paper und Evaluation ',
          background:'#E56B6F', text:'Seminar Paper und Evaluation passieren direkt auf Ilias. Hier können die Studenten ihre Arbeiten direkt einhändigen. Ganz wichtig ist der Punkt, dass der Syllabus eingefügt sind und Studierende so jeweils schauen, wie sich der Ablauf ergeben wird, um sich frühzeitig auf die Vorlesung vorbereiten zu können. Die Units enthalten die wichtigsten Studien als Link zum Download. Lesen steht im Fokus dieser Mastervorlesung. ', }
      ]
    }, //ende

    'psychologische-diagnostik':{
      index: 6,
      quotecolor: '#4F542D',
      backgroundLight: '#EFF1E5',
      backgroundDark: '#D7DBBD', // TODO: Diese Farbe stimmt noch nicht
      title: 'Psycho&shy;logische Diagnostik',
      introductions:[
        'Wir freuen uns, dass Sie sich für unser Projekt interessieren! Wir haben damit Veranstaltungen der «Learning Analytics»-Gruppe unterstützt. Primär haben wir uns mit der Vorlesung «Psychologische Diagnostik» beschäftigt, welche sich an Masterstudierende richtet.',
        'Ein Bedürfnis war, den ILIAS-Kursraum für die Studierenden übersichtlicher zu gestalten (aufgrund der eigentlich komplexen Struktur). Um dies zu erreichen, haben wir eine thematische Unterteilung in acht Lerneinheiten eingebettet. Die einzelnen Bereiche wurden farblich getrennt. Ferner wurde gewünscht, Fragen aus Vorjahren zu verwenden. Dazu wurde das ILIAS-Tool «Glossar» eingesetzt.'
      ],
      introductionimg: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/Videos%2Feneasinnbild.jpg",
      lecturers:[
        {name: 'Dr. Natalie Borter', role: '', imgsrc2:"", imgsrc:'https://vipro2022.fra1.cdn.digitaloceanspaces.com/Natalie_Borter%20(Vic%20%26%20Enea)%20quadratisch.jpg',
        quote: 'Ich wollte die Benutzerfreundlichkeit meines ILIAS-Kurses verbessern. Hochmotiviert, selbständig und kreativ haben die eCoaches meine ersten Ideen konkretisiert, optimiert und umgesetzt. Danke liebe eCoaches, das Projekt ist ein echter Erfolg – aus meiner Sicht und aus Sicht der Studierenden!',
        orientation: 'right'},
        {name: 'Victoria Habermacher', role: 'eCoach', imgsrc2:"", imgsrc: 'https://vipro2022.fra1.cdn.digitaloceanspaces.com/VictoriaHabermacher%20quadratisch.jpg',
        quote: ' Zufrieden blicke ich auf das eCoach-Projekt zurück, bei dem ich mit vielen tollen Menschen zusammenarbeiten durfte. Ich konnte viel Neues zu didaktischen und technischen Themen lernen. Besonders erfreulich ist, dass wir dieses Wissen direkt umsetzen und mehrere Veranstaltungen unterstützen konnten.',
        orientation: 'left'},
        {name: 'Enea Atroce', role: 'eCoach', imgsrc2:"", imgsrc:'https://vipro2022.fra1.cdn.digitaloceanspaces.com/EneaAtroce%20quadratisch.jpg',
        quote: 'Das Projekt war eine sehr interessante Erfahrung, da viele Personen daran beteiligt waren und somit viele Bedürfnisse und Ideen eingebracht wurden. Es war anregend, all dies miteinander zu verbinden und verschiedene Kurse zu kreieren. Des Weiteren war es auch sehr spannend, in einem Tandem mit zwei eCoaches zu arbeiten, da wir uns in unseren Fähigkeiten sehr gut ergänzt haben.',
        orientation: 'right'}
      ],
      timelineItems:[
        {
          index: 0,
          title: "Kennenlernphase",
          content:
            "Zunächst haben wir uns einen Überblick über die beteiligten Personen beschafft. Da wir im Verlauf des Projekts Kurse von mehreren Dozierenden bearbeitet haben, waren mehrere Personen involviert. Das heisst, nebst einer Kennenlernphase zu Beginn, haben wir während des Projekts laufend neue Gesichter kennenlernen dürfen.",
          src: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/SB_Kennenlernphase%20Quadratisch.png"
        },
        {
          index:1,
          title: "Planungsphase",
          content:
            "Als nächstes wurde für die jeweiligen Veranstaltungen ein Vorgehen geplant. Insbesondere stand dabei die Frage im Zentrum,  wie man einen ILIAS-Kursraum gestalten kann. welcher sowohl die Bedürfnisse der Studierenden als auch diejenigen der Dozierenden berücksichtigt. Nebst der Umsetzung konkreter Kurse kam bereits am Anfang der Zusammenarbeit der Wunsch auf, eine Vorlage zu erstellen. Diese Vorlage adressiert Dozierende, welche am Projekt «Learning Analytics» teilnehmen.",
          src: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/SB_Planungsphase%20Quadratisch.png"
        },
        {
          index: 2,
          title: "Ausführphase",
          content:
            'Später wurden verschiedene ILIAS-Tools miteinander verglichen und evaluiert, um zu entscheiden, welche sich für den spezifischen Kurs am besten eignen. Nach der tatsächlichen Umsetzung konnte man einen schnellen Lerneffekt wahrnehmen, die Aufbereitung der folgenden Kurse verlief bedeutend schneller. Während der Umsetzung wurde laufend Rücksprache mit den Dozierenden gehalten. So konnten die Veränderungen durch die Dozierenden beurteilt und Optimierungen vorgenommen werden.',
          src: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/SB_Ausfu%CC%88hrungsphase%20Quadratisch.png"
        },
        {
          index: 3,
          title: "Abschlussphase",
          content:
            'Nachdem die ILIAS-Kursräume gestaltet wurden, haben wir Anleitungen für den Template-Kurs erstellt. Diese sollen sicherstellen, dass unsere Arbeit längerfristig genutzt werden kann – sowohl von den Dozierenden, mit denen wir bereits gearbeitet haben als auch von weiteren Dozierenden. Die Anleitungen erklären die Nutzung des Template-Ordners und die relevanten ILIAS-Funktionen. Es wurden diverse Video-Anleitung sowie eine PDF-Anleitung erstellt.',
          src: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/test.png"
        }
      ],

      //TODO: insrert correct images
      contents:[
        { imgsrc:'https://vipro2022.fra1.cdn.digitaloceanspaces.com/Ilias-Special%2FAllgemein_Enea_Victoria.png', orientation: 'left', title:'ILIAS-Kursraum',
          background:'#D7DBBD', text:'Der Kurs weist folgenden Aufbau auf: Nach einem Block mit organisatorischen Infos folgen die Lerneinheiten. Weiter unten gibt es einen Bereich, welcher sich mit dem Statistikprogramm «R» befasst. Am Schluss befindet sich ein Objekteblock mit allen Materialien sowie ILIAS-Objekten. Das Kernelement des Kurses sind die Lerneinheiten, da diese den vermittelten Vorlesungsstoff beinhalten. In den einzelnen Themen-Ordnern befinden sich die theoretischen Grundlagen, welche mittels Podcasts und Literatur selbständig erarbeitet werden. Es folgen obligatorische und fakultative Übungsaufgaben sowie das Glossar. Das Glossar kann beim Lösen der Übungen helfen, da es aus Fragen und Antworten aus Vorjahren besteht. Damit keine Fragen offen bleiben, kann man sich zudem auch zu einer Fragestunde in Präsenz anmelden. Als letztes Element findet man weiterführende Zusatzmaterialien.' },
          { imgsrc:'https://vipro2022.fra1.cdn.digitaloceanspaces.com/Ilias-Special%2FSpecial_Victoria_Enea.png', orientation: 'right', title:'Specials',
          background:'#EFF1E5', text:'Nachdem wir mehrere ILIAS-Kurse gestaltet haben, wurde ein Template-Kurs erstellt. Darin enthalten ist eine Vorlage, welche man exportieren und in weitere Kurse integrieren kann. Des Weiteren kann diese Vorlage auch sehr einfach auf Folgesemester übertragen werden, weshalb der ILIAS-Kursraum nicht neu gestaltet und die Materialien nicht neu geordnet werden müssen. Für die Lerneinheiten haben wir gleich zwei Vorlagen erstellt. Aus diesen können die Dozierenden denjenigen Aufbau wählen, der am besten zu ihrer Veranstaltung und ihren Bedürfnissen passt. Dabei haben sie in ILIAS die Wahl zwischen einem Zwei-Spaltenlayout und einem Ein-Spaltenlayout.' }
      ],
      videosrc: 'https://vipro2022.fra1.cdn.digitaloceanspaces.com/Videos%2FEnea%20%26%20Victoria%20Video.mp4',
      videoBG: '#D7DBBD'
    },

    'affektive-neurowissenschaften':{
      index: 7,
      quotecolor: '#100A54',            //Jedes Projekt hat eine Nummer von 1-9, die Nummern sind identisch mit der id: eigenschaft auf den Würfeln
      backgroundLight: '#E5E4F1',       //Jedes Projekt hat eine eigenes Farbkonzept. Die Projektseite selbst hat dann jeweils einen helleren und einen dunkleren Farbton
      backgroundDark: '#C9C6E2',
      title: 'Affektive Neuro&shy;wissen&shy;schaften',       // Der Titel wird so auf der Projektseite Angezeigt
      introductions:[
        'Unsere Zusammenarbeit betraf die Mastervorlesung «Affektive Neurowissenschaften» des HS22. Durch einen kommunikativen Austausch konnte ein strukturierter ILIAS-Kursraum und somit auch eine ansprechende Lernumgebung gestaltet werden. Mithilfe von Kacheln und Akkordeons wurde in ILIAS Ordnung zwischen den Folien, Dateien und Videos geschaffen. Zahlreiche Ideen für die Förderung von Interaktion während des Unterrichts flossen und wir bestimmten gemeinsam, welche interaktiven Elemente wie bestmöglich eingesetzt werden konnten.',
        'Wenn es euch interessiert, wie genau wir dies erreicht haben, könnt ihr die weiteren Abschnitte durchforsten. Viel Spass dabei!'
        // 'Das war der Anknüpfungspunkt für unser eCoach-Projekt. Immer noch überzeugt von der Qualität des Konzepts haben wir uns die Umsetzung im Detail angeschaut.',
        //'<ul><li>Digitale Struktur erschaffen, welche Studierende selbstst&auml;ndig durch das Semester f&uuml;hrt<br>Selbststudium und Pr&auml;senzveranstaltung besser Verkn&uuml;pfen<br>Repetitionsfragen digitalisieren<br>Spezialevaluation erstellen</li></ul>'
      ],
      introductionimg: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/Finale-Sinnbilder%2FRaw%20Ronja%20Neuro%20Sinnbild.JPG",      // TODO: change picture
      lecturers:[                                                                                                                                           // hier kann einer oder mehrere Dozenten aufgelistet werden. Die Eigenschaft orientation sagt aus, ob der Text rechts oder links des Bildes ist// auch hier kann einer oder mehrere eCoaches aufgelistet werden. Die Eigenschaften sind identisch zu denen der Dozierenden
        {
          name: 'Ronja Federspiel', role: 'eCoach', imgsrc2:"", imgsrc:'https://vipro2022.fra1.cdn.digitaloceanspaces.com/RonjaFederspiel%20quadratisch.jpg',
          quote: 'Es hat mir grossen Spass gemacht, zusammen zu brainstormen. Ebenso ergab sich mir bei diesem Projekt die Möglichkeit, einen Kursraum frei zu gestalten. Daran habe ich schnell Gefallen gefunden, da es mir erlaubte, meiner Kreativität freien Lauf zu lassen.',
          orientation: 'left'}
      ],
      timelineItems:[                                                                                                                                      // Eine liste mit allen Einträgen der Timeline. Es kann eine beliebige anzahl an Einträgen gemacht werden.
        {
          index: 0,             // der Index sollte bei 0 starten und die weiteren einträge müssen durchgehend nummeriert sein
          title: "Kennenlernphase",
          content:
              "Meine Dozentin und ich lernten uns über Teams kennen. Als Erstes sammelte ich dann Informationen über den Kurs. So erfuhr ich beispielsweise, dass die Vorlesungsinhalte erst noch entwickelt werden mussten. Dies war wichtig, um einzuplanen, wie viel Zeit seitens der Dozentin zur Verfügung stand. Wir tauschten uns auch darüber aus, was sie sich von unserer Zusammenarbeit erhoffte und wie ich am besten meine Fähigkeiten zur Verfügung stellen konnte.\n",
          src: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/SB_Kennenlernphase%20Quadratisch.png"
        },
        {
          index:1,
          title: "Planungsphase",
          content:
              "Hier überlegten wir uns zum Beispiel, was für eine Farbpalette für den Kursraum in Frage kam. Dabei haben wir uns für eine Pastellpalette entschieden, die eine beruhigende Wirkung haben sollte. Ebenso schufen wir uns einen Überblick darüber, welche interaktiven Elemente wir nutzen könnten.",
          src: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/SB_Planungsphase%20Quadratisch.png"
        },
        {
          index: 2,
          title: "Ausführphase",
          content:
              "Der Kursraum bestand nun aus 14 Kacheln, wobei jede Kachel alle Dateien zu der jeweiligen Vorlesung enthielt. Ein bis zwei Videos wurden angefertigt, um die Prozesse des Gehirns besser veranschaulichen zu können. Die interaktiven Elemente, so eine Wörterwolke, Single-Choice-Fragen etc., wurden zuletzt mit Slido direkt in die PowerPoint-Präsentationen eingebaut. Auch erstellten wir ein Frageforum auf Padlet, welches ansprechend gestaltet wurde.",
          src: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/SB_Ausfu%CC%88hrungsphase%20Quadratisch.png"
        },
        {
          index: 3,
          title: "Abschlussphase",
          content:
              "Ein Abschlussgespräch wurde geführt, wo letzte Anliegen vorgetragen wurden. Der Kursraum kann so wie er ist kopiert und mit einer nun bereitliegenden Anleitung weitergeführt werden.",
          src: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/test.png"
        }
      ],
      contents:[ // hier ist eine Liste von beliebiger Länge, in der die weiteren Impressionen des Projektes wiedergegeben werden können. Jeder Eintrag räpresentiert ein Bild mit dem seitlich angebrafchten Text. orientation
        { imgsrc:'https://vipro2022.fra1.cdn.digitaloceanspaces.com/Ilias-Special%2FAllgemein_Ronja_Neuro(tatjana).png', orientation: 'left', title: 'ILIAS-Kursraum', // orientation left bedeutet, dass das Bild auf der linken seite ist. Bei Background muss abwechslungsweise der hellere oder der dunklere farbton angegeben werden.
          background:'#E5E4F1', text:'Im ILIAS-Kursraum befinden sich zuoberst eine Willkommensnachricht an die Studierenden sowie die wichtigsten Instruktionen und verlinkten Informationen. Rechts davon wurde in der Hälfte des Semesters eine Abstimmung aufgeschaltet, bei der die Unterrichtsgeschwindigkeit der Lehrveranstaltung eingestuft wurde. 14 Kacheln sind den jeweiligen Vorlesungsterminen gewidmet. Jede Kachel hat eine eigene Farbe und ist mit dem Vorlesungsthema beschriftet. Innerhalb dieser Kacheln findet man in den entsprechenden Akkordeons die Folien, Videos, Literatur und das PDF der eingegangenen Fragen auf Padlet. Zusätzlich zu diesen 14 Kacheln gibt es eine Kachel für die Podcasts und eine für den erstellten Selbsttest zur Prüfungsvorbereitung.' },
        { imgsrc:'https://vipro2022.fra1.cdn.digitaloceanspaces.com/Ilias-Special%2FSpecial_Ronja_Neuro%20(Tatjana).png', orientation: 'right', title: 'Specials',
          background:'#C9C6E2', text:'Statt eines Frageforums auf ILIAS wurde eines auf Padlet erstellt. Die Überlegung dabei war, dass es ansprechender gestaltet werden konnte und die Fragen sowohl anonym gestellt, als auch anschliessend als PDF heruntergeladen werden konnten. Toll an Padlet ist, dass Fotos (z. B. der nicht verstandenen Textstelle) zu den Beiträgen hinzugefügt werden können. Ebenfalls wurden die Studierenden aufgefordert, einen Versuch zu wagen, die Fragen untereinander zu beantworten. Die Studierenden konnten zudem Fragen favorisieren: Je mehr Stimmen, desto mehr Studis wollten die Frage beantwortet bekommen.' }
      ],
      videosrc: 'https://vipro2022.fra1.cdn.digitaloceanspaces.com/Videos%2FRonja%20Neuro%20Video%201.mp4',
      videoBG: '#E5E4F1'
    },

    'einfuehrung-in-qualitative-forschungsmethoden':{         // hier beginnt das Projekt advanced organization I. "advanced-organization-I" ist ein slug und muss mit der route aus den würfeln übereinstimmen. Sonst kann das Projekt nicht gefunden werden.
      index: 8,
      quotecolor: '#22404D',                        //Jedes Projekt hat eine Nummer von 1-9, die Nummern sind identisch mit der id: eigenschaft auf den Würfeln
      backgroundLight: '#E5EEF0',       // Jedes Projekt hat eine eigenes Farbkonzept. Die Projektseite selbst hat dann jeweils einen helleren und einen dunkleren Farbton
      backgroundDark: '#CBDCE1',
      title: 'Einführung in qualitative Forschungs&shy;methoden',       // Der Titel wird so auf der Projektseite Angezeigt
      introductions:[
        'Hallo und viel Freude beim Entdecken unseres Projekts!',
        'Die Mastervorlesung «Einführung in qualitative Forschungsmethoden» (HS22 und FS23) war Teil der grösseren Veranstaltung «Empirisches Forschungspraktikum in Verwaltungswissenschaft». Durch unsere Zusammenarbeit konnte die Navigation im ILIAS-Kursraum erleichtert werden. Es war entscheidend für die Erarbeitung des Inhalts, dass die Studierenden in Kleingruppen arbeiten mussten. ',
        'Mithilfe einer durchdachten Gestaltung des Kursraums, der ILIAS-Gruppenfunktion und eines Miroboards konnte ein strukturierter Online-Arbeitsplatz geschaffen werden. Auf diesen konnten die Studierenden sowohl synchron als auch asynchron zugreifen. Ebenso haben wir uns dafür entschieden, die Vorlesungsfolien in ILIAS selbst zu entwerfen, statt sie als blosse Datei hochzuladen. Genaueres zu unserem Projekt finden Sie weiter unten.'
      ],
      introductionimg: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/Raw%20Ronja%20Quali.jpg",      // die Quelle zu dem ersten Bild ganz rechts
      lecturers:[                                                                                                                                           // hier kann einer oder mehrere Dozenten aufgelistet werden. Die Eigenschaft orientation sagt aus, ob der Text rechts oder links des Bildes ist
        {name: 'Dr. Emamdeen Fohim', role: '', imgsrc2:"", imgsrc:'https://vipro2022.fra1.cdn.digitaloceanspaces.com/EmamdeenFohim%20(Ronja%20Quali)%20quadratisch.jpg',    // TODO get real names
          quote: 'Dank der Unterstützung des eCoaches wollte ich meinen bestehenden Kurs zu qualitativen Methoden modernisieren und interaktiver gestalten. Hierfür haben wir die ILIAS-Plattform mit einer übersichtlichen und verlinkten Graphik neugestaltet. Das ebenfalls erstellte Online-Miro-Board können die Studierenden für ihre Gruppenseminararbeit nutzen. Die Hilfe des eCoaches war wertvoll, nicht nur in der Umsetzung der digitalen Lösungen, sondern auch im Einbringen von kreativen Lösungsansätzen.\n',
          orientation: 'right'},
        {name: 'Ronja Federspiel', role: 'eCoach', imgsrc2:"", imgsrc: 'https://vipro2022.fra1.cdn.digitaloceanspaces.com/RonjaFederspiel%20quadratisch.jpg',
          quote: 'Aus dieser guten Zusammenarbeit ist etwas Wunderbares entstanden. Spass machte es mir, die unterbreiteten Vorschläge zu besprechen und weiterzuentwickeln. Ich fand es spannend, bei einem auftretenden Problem eine Alternativlösung zu suchen und viele Ideen ausprobieren zu können.',
          orientation: 'left'}
      ],
      timelineItems:[                                                                                                                                      // Eine liste mit allen Einträgen der Timeline. Es kann eine beliebige anzahl an Einträgen gemacht werden.
        {
          index: 0,             // der Index sollte bei 0 starten und die weiteren einträge müssen durchgehend nummeriert sein
          title: "Kennenlernphase",
          content:
              "In einem ersten Gespräch besprachen wir, was unsere jeweiligen Vorstellungen dieser Zusammenarbeit waren und was wir uns davon erhofften. Dieses Projekt habe ich zeitlich erst ein wenig später übernommen. Doch da alle Tandems noch in der Planungsphase steckten, stellte dies kein grosses Problem dar und ich konnte bald einige Ideen vorstellen.",
          src: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/SB_Kennenlernphase%20Quadratisch.png"
        },
        {
          index:1,
          title: "Planungsphase",
          content:
              "Dieser Kurs wurde so konzipiert, dass anhand von sieben Phasen und über zwei Semester hinweg das Ziel verfolgt werden sollte, eine eigene qualitative Forschungsarbeit zu schreiben. Dabei wurden die Studierenden in Kleingruppen eingeteilt. Schlussendlich einigten wir uns darauf, dass die Kleingruppen einen Arbeitsplatz benötigen, um sich auch ausserhalb der Uni auszutauschen. Damit alles auch online verfügbar ist, wurde eine gute Struktur entwickelt.",
          src: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/SB_Planungsphase%20Quadratisch.png"
        },
        {
          index: 2,
          title: "Ausführphase",
          content:
              "Zuerst wurde ein Bild entworfen, welches die sieben Phasen darstellt. Danach sind sieben ILIAS-Ordner so gestaltet worden, dass sie PowerPoint-Folien ähneln. Dann wurden die einzelnen Phasenabschnitte des Bildes mit den ILIAS-Ordnern verlinkt. Somit konnte man durch einen Klick auf die gewünschte Phase direkt mit dem Unterricht starten. Wir erstellten ebenso anhand dieser Phasen ein Miro-Board, welches aber auch Platz für die Bearbeitung von Aufgaben hatte. Für den Online-Arbeitsplatz der Studierenden haben wir Gruppenordner erstellt. Sowohl von diesen als auch von den ILIAS-Folien aus konnte man auf das Miro-Board zugreifen.",
          src: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/SB_Ausfu%CC%88hrungsphase%20Quadratisch.png"
        },
        {
          index: 3,
          title: "Abschlussphase",
          content:
              "Die letzten Folien für das Frühlingssemester wurden erstellt und ein Abschlussgespräch wurde abgehalten. Der ganze Kurs kann derart für weitere Semester wiederverwendet werden, auch, da sich die Folien nun sehr einfach verändern lassen.",
          src: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/test.png"
        }
      ],
      //TODO: von Gianluca und Alexandra fehlt immer noch der eigentliche Inhalt bzw. ist zu vage
      contents:[ // hier ist eine Liste von beliebiger Länge, in der die weiteren Impressionen des Projektes wiedergegeben werden können. Jeder Eintrag räpresentiert ein Bild mit dem seitlich angebrafchten Text. orientation
        { imgsrc:'https://vipro2022.fra1.cdn.digitaloceanspaces.com/Ilias-Special%2FAllgemein_Ronja_Quali.png', orientation: 'left', title:'Ilias Kursraum',// orientation left bedeutet, dass das Bild auf der linken seite ist. Bei Background muss abwechslungsweise der hellere oder der dunklere farbton angegeben werden.
          background:'#CBDCE1', text:'Der ILIAS-Kursraum enthält eine Grafik, welche die sieben Phasen einer qualitativen Forschungsarbeit darstellt. Wird dort auf eine Phase geklickt, landet man ohne Umwege direkt auf den entsprechenden Vorlesungsfolien. Unter dem Bild befinden sich vier Gruppenordner und ein Ordner mit Lernmaterialien, der zur Unterstützung dient. Die Gruppenordner stellen einen Arbeitsplatz für die jeweilige Gruppe dar, in dem sie selbstständig Dateien teilen können und Zugriff auf das gemeinsame Miro-Board haben.' },
        { imgsrc:'https://vipro2022.fra1.cdn.digitaloceanspaces.com/Ilias-Special%2FSpecial_Ronja_Quali(Emi).png', orientation: 'right', title:'Specials',
          background:'#E5EEF0', text:'Um einen unkomplizierten Einstieg zu ermöglichen, wurde eine Grafik anhand der sieben Phasen erstellt, nach welcher sich der Unterricht richtet. Beispielsweise widmete sich die erste Vorlesungsstunde ganz dem Thema der ersten Phase, der «Hook». Für die mehrfache Im-Bild-Verlinkung wurde nach dem Einfügen des Bildes die Bearbeitungsfunktion von ILIAS genutzt. Bei den erweiterten Einstellungen gibt die Möglichkeit, einzelne Bereiche des Bildes auszuwählen und diese mit etwas zu verlinken. In diesem Fall waren dies die sieben Vorlesungs-Ordner. All diese Entscheidungen wurden so getroffen, dass der Kursraum gut strukturiert und leicht zu bedienen ist.' }
      ],
    videosrc: 'https://vipro2022.fra1.cdn.digitaloceanspaces.com/Videos%2FRonja%20Neuro%20Video%201.mp4',
      videoBG: '#CBDCE1'
    }, //ende

    'datensatzaufbereitung-in-r':{
      index: 9,
      quotecolor:'#0B471D',
      backgroundLight: '#C4E8CF',
      backgroundDark: '#E2F4E7',
      title: 'Datensatz&shy;aufbereitung in R',
      introductions:[
        'Das Masterseminar «Datensatzaufbereitung in R» diente zum Repetieren und Auffrischen der Inhalte zum Thema «R», welche bereits im Bachelorstudium angeschaut wurden. Aufgrund des komplexen Themas wurde hierbei in kleinen Gruppen (jeweils ca. 15 Personen) gearbeitet. Durch das Einführen von drei Gruppen an 15 Personen, welche jeweils rotierend alle drei Wochen einen Präsenztermin hatten, konnten wir die Kapazität dieses Kurses verdreifachen. Durch eine durchdachte ILIAS-Seite wurden die Lernenden durch die eLearning-Module sowie durch die Seminare geführt.'
      ],
      introductionimg: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/Finale-Sinnbilder%2FRaw%20Florian%20Sinnbild.JPG",
      lecturers:[
        {name: 'Prof. Dr. Jennifer Inauen und Dr. phil. Benjamin Ambühl', role: '', imgsrc2:"https://vipro2022.fra1.cdn.digitaloceanspaces.com/Benjamin_Ambuehl%20(Florian)quadratisch.jpg", imgsrc:'https://vipro2022.fra1.cdn.digitaloceanspaces.com/Jennifer_Inauen%20(Florian)quadratisch.jpg',
        quote: 'Dank dem eCoach war es uns möglich, die Kapazität zu steigern und das didaktische Konzept der Lehrveranstaltung an die erhöhte Anzahl Studierender anzupassen - und dabei die Qualität der Lehre beizubehalten! Unser eCoach war eine grosse Bereicherung und die Inputs aus der Zusammenarbeit werden auch über das Engagement hinaus Bestand haben (z. B. neuer ILIAS-Kursraum mit interaktiven Elementen).',
        orientation: 'right'},
        {name: 'Florian Meinigg', role: 'eCoach', imgsrc2:"", imgsrc: 'https://vipro2022.fra1.cdn.digitaloceanspaces.com/FlorianMeinigg%20quadratisch.jpg',
        quote: 'Die Zusammenarbeit mit Benjamin und Jennifer war speditiv und hat Spass gemacht. Insbesondere sind wir auf Komplexitäten in ILIAS gestossen, welche einfache Auswertungen erschweren. Mithilfe der Vorarbeit meines eCoach-Kollegen Sascha sowie meinen Erfahrungen aus dem Studium konnten wir jedoch gute individuelle Lösungen erstellen.',
        orientation: 'left'}
      ],
      timelineItems:[
        {
          index: 0,
          title: "Kennenlernphase",
          content:
            "Den Startschuss zum Projekt haben wir mit einem Onsite-Meeting gesetzt, damit man sich etwas kennenlernen kann. Natürlich haben wir währenddessen auch erste Gedanken zum Projekt gesammelt und erste Konzepte erstellt.",
          src: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/SB_Kennenlernphase%20Quadratisch.png"
        },
        {
          index:1,
          title: "Plaungsphase",
          content:
            "Vor Semesterbeginn hatten wir mehrere Meetings, in welchen wir die Details des ILIAS-Kursraumes und der Inhalte besprochen haben. Insbesondere war unser Fokus dabei auf die Kapazitätssteigerung des Kurses gerichtet.",
          src: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/SB_Planungsphase%20Quadratisch.png"
        },
        {
          index: 2,
          title: "Ausführungsphase",
          content:
            "Wir haben unseren Plan umgesetzt. Da sich in der Ausführung immer wieder Details herauskristallisieren, die nicht ganz klar waren, haben wir hier eine sehr agile Arbeitsweise gewählt. Die Inhalte waren von Beginn weg noch nicht ganz klar - entsprechend mussten wir laufend weiterplanen.",
          src: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/SB_Ausfu%CC%88hrungsphase%20Quadratisch.png"
        },
        {
          index: 3,
          title: "Abschlussphase",
          content:
            "Damit der ILIAS-Kursraum weiterverwendet werden kann, habe ich einen separaten Kursraum erstellt, welcher in das nächste Semester kopiert werden kann. Da dies nicht trivial ist, habe ich zusätzlich noch eine Anleitung dafür verfasst. Das Auswertungsskript habe ich derart erstellt, dass es mit einfachen Modifikationen auch im nächsten Jahr funktioniert.",
          src: "https://vipro2022.fra1.cdn.digitaloceanspaces.com/test.png"
        }
      ],
      contents:[
        { imgsrc:'https://vipro2022.fra1.cdn.digitaloceanspaces.com/Ilias-Special%2FAllgemein_Florian.png', orientation: 'left', title:'Ilias Kursraum',
          background:'#E2F4E7', text:'Da wir mehrere Gruppen hatten, bestand unser ILIAS-Kursraum aus mehreren Ebenen. Als erstes liegt die Hauptseite vor, auf welcher wir die Inhalte publizieren, welche von allen Studierenden benötigt werden (d. h. Organisatorisches, Skripts, PDFs, Testdaten etc.). Danach haben wir für jede Gruppe einen Gruppenordner erstellt. In diesem sind die Lernmodule enthalten, deren Daten auf die Gruppen abgestimmt sind (letztere hatten verschiedene Onsite-Termine). In diesen Modulen haben wir noch die Daten von der Hauptseite verlinkt, damit die Studierenden direkt sehen, welche Dokumente zu welchem eLearning-/Onsite-Termin gehören.' },
          { imgsrc:'https://vipro2022.fra1.cdn.digitaloceanspaces.com/Ilias-Special%2FSpecial_Florian.png', orientation: 'right', title:'Ziel',
          background:'#C4E8CF', text:'Das Ziel war, dass die Studierenden mithilfe dieser Hierarchie klar erkennen kann, was ihre Tasks für die jeweilige Woche sind. Da wir mit Tests und Abgaben gearbeitet haben, hatten wir am Ende für jede Gruppe einzelne Tests/Abgaben (über 30 Stück). Da diese Auswertung zeitaufwändig ist, haben wir ein Skript erstellt, welche das Ganze in einer Excel-Liste zusammenfasst.' }
      ],
      videosrc: 'https://vipro2022.fra1.cdn.digitaloceanspaces.com/Videos%2FFlorian%20Video.mp4',
      videoBG: '#E2F4E7'
    }


  }
  }

const mutations = {
    mute(state){
      state.muted = true
    },
    unMute(state){
      state.muted = false
    },
    pause(state){
      state.paused = true
    },
    unPause(state){
      state.paused = false
    },
    
    turnFront(state){
      state.cubes[0].orientation = "front"
    },
    turnLeft(state){
      state.cubes[0].orientation = "left"
    },
    turnTop(state){
      state.cubes[0].orientation = "top"
    },
    turnRight(state){
      state.cubes[0].orientation = "right"
    },
    focus (state, id){
      //state.cubesBefore = state.cubes.slice(0,3)
      //state.cubesAfter = state.cubes.slice(4)
      state.cubesBefore = state.cubes.splice(0,id-1)
      state.cubesAfter = state.cubes.splice(1)
      state.menuViewUntouched = false;
    },
    unFocus (state){
      state.cubes = state.cubesBefore.concat(state.cubes)
      state.cubes = state.cubes.concat(state.cubesAfter)
    },
    
    expandMenu(state) {
      state.menuView = 'big'
      this.commit('unFocus')
    }, 
    reduceMenu(state, id) {
      state.menuView = 'reduced'
      this.commit('focus', id)
    }

  }

export default new Vuex.Store({
  state,
  mutations
})

Vue.use(Vuex)