<template>
  <div >
    <div  class="cscene">
      <div  v-if="cube.id != 5" class="cube" :class="currentOrientation" @click="navigate" >
        <div  class="cube__face cube__face--front" :style="'background: ' +cube.colors[0].background + ';'" >
          <img :src="cube.frontFace" style="max-width: 100%">
        </div>
        <div  v-if="$route.path !== '/'" class="cube__face cube__face--back" :style="'background: ' +cube.colors[1].background + ';'">
        </div>
        <div  v-if="$route.path !== '/'" class="cube__face cube__face--right" :style="'background: ' +cube.colors[2].background + ';'">
          <p style="margin-top:-15 px; font-size: 2.8em;">Was</p>
        </div>
        <div  v-if="$route.path !== '/'" class="cube__face cube__face--left" :style="'background: ' +cube.colors[3].background + ';'">
          <p style="margin-top:-15 px; font-size: 2.8em;">Wann</p>
        </div>
        <div  v-if="$route.path !== '/'" class="cube__face cube__face--top" :style="'background: ' +cube.colors[4].background + ';'">
          <p style="margin-top:-15 px; font-size: 2.8em;">Wer</p>
        </div>
        <div  v-if="$route.path !== '/'" class="cube__face cube__face--bottom" :style="'background: ' +cube.colors[5].background + ';'">
          <div class="content">
            Team
          </div>
        </div>
      </div>
      <div v-else>
        <div  class="cube__face cube__face--front" :style="'background: ' +cube.colors[0].background + '; bottom: 30px !important;'" >
          <img :src="cube.frontFace" style="max-width: 100%">
        </div>
        <div  v-if="$route.path !== '/'" class="cube__face cube__face--back" :style="'background: ' +cube.colors[1].background + ';'">
        </div>
        <div  v-if="$route.path !== '/'" class="cube__face cube__face--right" :style="'background: ' +cube.colors[2].background + ';'">
          <p style="margin-top:-15 px; font-size: 2.8em;">Was</p>
        </div>
        <div  v-if="$route.path !== '/'" class="cube__face cube__face--left" :style="'background: ' +cube.colors[3].background + ';'">
          <p style="margin-top:-15 px; font-size: 2.8em;">Wann</p>
        </div>
        <div  v-if="$route.path !== '/'" class="cube__face cube__face--top" :style="'background: ' +cube.colors[4].background + ';'">
          <p style="margin-top:-15 px; font-size: 2.8em;">Wer</p>
        </div>
        <div  v-if="$route.path !== '/'" class="cube__face cube__face--bottom" :style="'background: ' +cube.colors[5].background + ';'">
          <div class="content">
            Team
          </div>
        </div>
      </div>
    </div>
    <p v-if="!isSmall" class="subtitle" style="" @click="navigate">{{cube.faculty}}</p>
    <p v-if="cube.id!=5&&isSmall" class="subtitle" style="" @click="toggleMenu">{{cube.faculty}}</p>
  </div>
    
</template>

<script>

export default {
  
  //:style="{background: currentCube.background[0]}"
  name: 'Cube',
  /**data() {
    return {
        currentOrientation: 'front'
    }
  },*/
  props:{
      cube: Object,
      isSmall: Boolean
  },
  computed:{
      currentOrientation () {
          return "show-" + this.cube.orientation;
      }
  },
  methods: {
      turnCube: function(newOrientation) {
          this.currentOrientation = 'show-' + newOrientation;
      },
      navigate: function() {
        this.$router.push({ name: 'project', params: { id: this.cube.route } })
      },
      toggleMenu: function() {
        this.$router.push({path:'/'})
      //this.$store.commit('toggleMenu')
    },
  }
}
</script>

<!-- Add "scoped" 
    .cube__face--front  { background: #6D597A; }
    .cube__face--right  { background: #82718E; }
    .cube__face--back   { background: #9889A2; }
    .cube__face--left   { background: #ADA0B5; }
    .cube__face--top    { background: #C3B8C9; }
    .cube__face--bottom { background: #D8D0DD; }
attribute to limit CSS to this component only  background: hsla( 180, 100%, 50%, 1);-->
<style scoped>
.subtitle{
  transform: translate(25px,-20px);
  color: #0087E0;
  text-align: left;
}

.subtitle:hover{
  color: #99D6FF;
}

.cscene {
        width: 150px;
        height: 150px;
        margin: 25px;
        perspective: 999px;
        perspective-origin: -50% -50%;
        }

    .cube {
        width: 140px;
        height: 140px;
        position: relative;
        transform-style: preserve-3d;
        transform: translateZ(-70px);
        transition: transform 1s;
        cursor: pointer;
        }

    .cube.show-front  { transform: translateZ(-70px) rotateY(   0deg); }
    .cube.show-right  { transform: translateZ(-70px) rotateY( -90deg); }
    .cube.show-back   { transform: translateZ(-70px) rotateY(-180deg); }
    .cube.show-left   { transform: translateZ(-70px) rotateY(  90deg); }
    .cube.show-top    { transform: translateZ(-70px) rotateX( -90deg) rotateZ(0deg); }
    .cube.show-bottom { transform: translateZ(-70px) rotateX(  90deg) rotateZ(020deg); }

    .cube__face {
        position: absolute;
        width: 140px;
        height: 140px;
        line-height: 140px;
        color: white;
        text-align: center;
        
        }
    
    .content {
        padding: 10px;
    }



    .cube__face--front  { transform: rotateY(  0deg) translateZ(70px); }
    .cube__face--right  { transform: rotateY( 90deg) translateZ(70px); }
    .cube__face--back   { transform: rotateY(180deg) translateZ(70px); }
    .cube__face--left   { transform: rotateY(-90deg) translateZ(70px); }
    .cube__face--top    { transform: rotateX( 90deg) translateZ(70px); }
    .cube__face--bottom { transform: rotateX(-90deg) translateZ(70px); }

    label { margin-right: 10px; }

</style>