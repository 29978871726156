<template>
  <div class="row">
    <div class="grid-item left" >
      <div class="textAndLine">
        <div class="textcontent">
          <h3>{{currentItem.title}}</h3>
          <p style="margin-top: 20px;">{{currentItem.content}}</p>
        </div>
        <div class="lines">
          <div v-for="item in items" :key="item.index">
            <div v-if="item.index > 0" class="line"  :style="{height : lineHeight + 'px'}"></div>
            <div class="dot full" v-if="item.index == currentItemIndex" @click="currentItemIndex = item.index"></div>
            <div class="dot" v-if="item.index != currentItemIndex" @click="currentItemIndex = item.index"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid-item right">
      <div class="timeline--img">
        <transition name="fade">
          <img :src="currentItem.src"  data-toggle="tooltip" data-placement="bottom" title="goTop" alt="" class="img" style="margin-bottom: -5px;">
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Timeline',
  components:{
  },
  props:{
    items:Array
  },
  data() {
    return {
      currentItemIndex: 0
    };
  },
  computed: {
    currentItem: function () {
      return this.items[this.currentItemIndex];
    },
    lineHeight: function () {
      return 300 / (this.items.length -1) -25
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.row{
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  overflow: hidden;
}
.img{
  flex: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.timeline--img {
  height: 100%;
}

.dot{
  width:18px;
  height:18px;
  border-radius:15px;
  margin-right: 0px;
  border: 6px solid;
  border-color: black;
  cursor: pointer;
}
.full{
  background: black;
}
.line{

  width:6px;
  background: black;
  margin-left:12px;

}
.textcontent{
  float: left;
  overflow: hidden;
  width: 70%;
}

.textAndLine {
  padding-left: 80px;
  padding-top: 80px;
  padding-bottom: 400px;
}

.lines{
  margin-right: 50px;
  float: right;
}
.left{
  flex: 1;
  min-width: 400px;

}
.right {
  flex: 1;
  min-width: 400px;
}
</style>