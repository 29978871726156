<template>
  <div class="container">
    <div v-if="orientation == 'left'" class="left">
      <img :src="imgsrc" alt=""  :style="{borderLeft: color + ' solid 20px'}">
      <div v-if="quote !== ''" class="textcontent textleft">
        <span class="quote">„{{quote}}“</span><br>
        <span class="name nameLeft" style="float:right;">– {{name + minus + role}}</span>
      </div>
    </div>
    <div v-if="orientation == 'right'" class=" right">

      <div class="images">
        <img :src="imgsrc" alt="" :style="{borderRight: color + ' solid 20px'}">
        <img v-if="imgsrc2 !==''" :src="imgsrc2" alt="" :style="{borderRight: color + ' solid 20px'}">
      </div>
      <div class="textcontent textright">
        <span class="quote">„{{quote}}“</span><br>
        <span class="name nameRight" style="float:left; "> – {{name + minus + role}}</span>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Portrait',
  props: {
    quote: String,
    name: String,
    role: String,
    imgsrc: String,
    imgsrc2: String,
    orientation: String,
    color: String
  },
  computed:{
    minus: function(){
      if (this.role !== "") {
        return(' - ')
      }else{
        return('')
      }
    }
  }
}
/*
  @media (max-width: 1270px) {
    .quotecontainer{
          height: auto;
          width: 100%;
        }
  }
  @media (min-width: 1270px) {
    .quotecontainer{
          height: 20vw;
        }
  }

*/

</script>

<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');
.container{
  clear: left/right/both;
  position: inherit;
  width: 100%;
  margin:0px;
  float: top;
  padding-top: 40px;
  padding-bottom: 40px;
  overflow: hidden;
}

.quote{

  font-family: 'Roboto', sans-serif;
  /*font-size: 1.15em;*/
  font-style: italic;

  clear:both;
  display: inline-block;
  width: 100%;
}

.textleft {
  margin-right: 250px !important;
}
.textright {
  margin-left: 250px !important;
}


.name{
    margin-top: 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    overflow:hidden;
}

.nameRight {

}

.nameLeft {

}

.left > div{
  text-align: right;
  margin-right: 0%;
;
}
.right > div{
  text-align: left;
  margin-left:0% ;
}
img{
  width: 200px;
  height: auto;
  
  margin-bottom: 0px;
}


.images {
  width: 250px;
  float: left;
}


.left > img{
  float: right; margin-left:0px;
  padding-right:0px;
  margin-top:0px;
}
.right > img{
  float: left;
  margin-right:0px;
  padding-right: 0px;
}
.left{
  margin-left:0px;
  margin-right: 0px;
}
.right{
  padding-top:50px;
  
}

@media (max-width: 440px) {
  img{
    width: 100px;
    height: auto;

    margin-bottom: 0px;
  }


  .images {
    width: 150px;
    float: left;
  }

  .textleft {
    margin-right: 150px !important;
  }
  .textright {
    margin-left: 150px !important;
  }

}

@media (max-width: 1000px) {

  /*.left > img{
      float:none;
      margin-left: 0px
        }*/
    .right > img{
      float:none;
    }
    .left > div{
      text-align: left;
    }
  }
</style>